<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 ">
                <h4 class="title-in-h">{{$t('property.data.header')}}</h4>
                <breadcrumb >
                    <breadcrumb-item  v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                        <router-link :to="{path:breadcrumb.path}"  class="color-blue-var">{{breadcrumb.name}}</router-link>
                    </breadcrumb-item>
                    <breadcrumb-item active v-else>
                        {{breadcrumb.name}}
                    </breadcrumb-item>
                </breadcrumb>
            </div>
        </div>



        <div class="row">

            <div class="col-12">
                <card class="mt-2" v-loading="screenLoading"
                      element-loading-lock="true"
                      :element-loading-text="screenText"
                      element-loading-spinner="el-icon-loading">

                    <div>
                        <div class="col-sm-12">
                            <div class="box-info">
                                <div class="box-top">
                                    <a
                                    v-if="writting_permission" 
                                    @click="info_page.form = !info_page.form" class="action-form color-blue-var">
                                      <i class="fa fa-pencil"></i>
                                      </a>
                                    <h6 class="color-primary">{{property.name}}</h6>


                                    <div class="group-data-item">
                                        <label>
                                            {{$t('form.razon')}}:
                                        </label>
                                        <fg-input type="text"
                                                  class="inp-itm"
                                                  :name="$t('form.mark')"
                                                  v-model="property.business_name"
                                                  v-if="info_page.form"
                                                  @keypress="changesHistory()"
                                        ></fg-input>

                                        <label v-else class="inp-itm">
                                            <span>{{property.business_name}}</span>
                                        </label>
                                    </div>
                                    <div class="group-data-item">
                                        <label>
                                            {{$t('form.rfc')}}:
                                        </label>
                                        <fg-input type="text"
                                                  class="inp-itm"
                                                  :name="$t('form.mark')"
                                                  v-model="property.rfc"
                                                  v-if="info_page.form"
                                                  @keypress="changesHistory()"
                                        ></fg-input>

                                        <label v-else class="inp-itm">
                                            <span>{{property.rfc}}</span>
                                        </label>
                                    </div>

                                    <div class="group-data-item">
                                        <label>
                                            {{$t('form.street')}}:
                                        </label>
                                        <fg-input type="text"
                                                  class="inp-itm"
                                                  :name="$t('form.mark')"
                                                  v-model="property.b_street"
                                                  v-if="info_page.form"
                                                  @keypress="changesHistory()"
                                        ></fg-input>

                                        <label v-else class="inp-itm">
                                            <span>{{property.b_street}}</span>
                                        </label>
                                    </div>

                                    <div class="group-data-item">
                                        <label>
                                            {{$t('form.ext_number')}}:
                                        </label>
                                        <fg-input type="text"
                                                  class="inp-itm"
                                                  :name="$t('form.ext_number')"
                                                  v-model="property.b_ext_no"
                                                  v-if="info_page.form"
                                                  @keypress="changesHistory()"
                                        ></fg-input>

                                        <label v-else class="inp-itm">
                                            <span>{{property.b_ext_no}}</span>
                                        </label>
                                    </div>

                                    <div class="group-data-item">
                                        <label>
                                            {{$t('form.int_number')}}:
                                        </label>
                                        <fg-input type="text"
                                                  class="inp-itm"
                                                  :name="$t('form.int_number')"
                                                  v-model="property.b_int_no"
                                                  v-if="info_page.form"
                                                  @keypress="changesHistory()"
                                        ></fg-input>

                                        <label v-else class="inp-itm">
                                            <span>{{property.b_int_no}}</span>
                                        </label>
                                    </div>

                                    <div class="group-data-item">
                                        <label>
                                            {{$t('form.suburb')}}:
                                        </label>
                                        <fg-input type="text"
                                                  class="inp-itm"
                                                  :name="$t('form.suburb')"
                                                  v-model="property.b_suburb"
                                                  v-if="info_page.form"
                                                  @keypress="changesHistory()"
                                        ></fg-input>

                                        <label v-else class="inp-itm">
                                            <span>{{property.b_suburb}}</span>
                                        </label>
                                    </div>


                                    <div class="group-data-item">
                                        <label>
                                            {{$t('form.zipcode')}}:
                                        </label>
                                        <fg-input type="text"
                                                  class="inp-itm"
                                                  :name="$t('form.zipcode')"
                                                  v-model="property.b_zp"
                                                  v-if="info_page.form"
                                                  @keypress="changesHistory()"
                                        ></fg-input>

                                        <label v-else class="inp-itm">
                                            <span>{{property.b_zp}}</span>
                                        </label>
                                    </div>

                                    <div class="group-data-item">
                                        <label>
                                            {{$t('form.city')}}:
                                        </label>
                                        <fg-input type="text"
                                                  class="inp-itm"
                                                  :name="$t('form.city')"
                                                  v-model="property.b_city"
                                                  v-if="info_page.form"
                                                  @keypress="changesHistory()"
                                        ></fg-input>

                                        <label v-else class="inp-itm">
                                            <span>{{property.b_city}}</span>
                                        </label>
                                    </div>
                                    <div class="group-data-item">
                                        <label>
                                            {{$t('form.state')}}:
                                        </label>
                                        <fg-input type="text"
                                                  class="inp-itm"
                                                  :name="$t('form.state')"
                                                  v-model="property.b_state"
                                                  v-if="info_page.form"
                                                  @keypress="changesHistory()"
                                        ></fg-input>

                                        <label v-else class="inp-itm">
                                            <span>{{property.b_state}}</span>
                                        </label>
                                    </div>
                                    <div class="group-data-item">
                                        <label>
                                            {{$t('form.country')}}:
                                        </label>
                                        <fg-input type="text"
                                                  class="inp-itm"
                                                  :name="$t('form.country')"
                                                  v-model="property.b_country"
                                                  v-if="info_page.form"
                                                  @keypress="changesHistory()"
                                        ></fg-input>

                                        <label v-else class="inp-itm">
                                            <span>{{property.b_country}}</span>
                                        </label>
                                    </div>

                                    <button type="buttton" class="btn btn-success btn-fill btn-wd"  :disabled="(records===0)" @click="checkForm('top')"  v-if="info_page.form">
                                        {{$t('form.update')}}
                                    </button>

                                </div>
                                <div class="box-bottom">
                                    <a 
                                    v-if="writting_permission"
                                    @click="info_page.form_bottom = !info_page.form_bottom" class="action-form color-blue-var">
                                      <i class="fa fa-pencil"></i>
                                    </a>

                                    <h6  class="color-primary"> {{$t('form.contact_data')}}</h6>


                                    <div class="group-data-item">
                                        <label>
                                            {{$t('form.name')}}:
                                        </label>
                                        <fg-input type="text"
                                                  class="inp-itm"
                                                  :name="$t('form.name')"
                                                  v-model="property.contact_name"
                                                  v-if="info_page.form_bottom"
                                                  @keypress="changesHistory('bottom')"
                                        ></fg-input>

                                        <label v-else class="inp-itm">
                                            <span>{{property.contact_name}}</span>
                                        </label>
                                    </div>
                                    <div class="group-data-item">
                                        <label>
                                            {{$t('form.number_phone')}}:
                                        </label>
                                        <fg-input type="text"
                                                  class="inp-itm"
                                                  :name="$t('form.name')"
                                                  v-model="property.contact_phone"
                                                  v-if="info_page.form_bottom"
                                                  @keypress="changesHistory('bottom')"
                                        ></fg-input>

                                        <label v-else class="inp-itm">
                                            <span>{{property.contact_phone}}</span>
                                        </label>
                                    </div>
                                    <div class="group-data-item">
                                        <label>
                                            {{$t('form.email')}}:
                                        </label>
                                        <fg-input type="text"
                                                  class="inp-itm"
                                                  :name="$t('form.name')"
                                                  v-model="property.contact_email"
                                                  v-if="info_page.form_bottom"
                                                  @keypress="changesHistory('bottom')"
                                        ></fg-input>

                                        <label v-else class="inp-itm">
                                            <span>{{property.contact_email}}</span>
                                        </label>
                                    </div>
                                    <div class="group-data-item">
                                        <label>
                                            {{$t('form.cdfi_use')}}:
                                        </label>
                                        <fg-input type="text"
                                                  class="inp-itm"
                                                  :name="$t('form.name')"
                                                  v-model="property.b_cfdi_usage"
                                                  v-if="info_page.form_bottom"
                                                  @keypress="changesHistory('bottom')"
                                        ></fg-input>

                                        <label v-else class="inp-itm">
                                            <span>{{property.b_cfdi_usage}}</span>
                                        </label>
                                    </div>
                                    <button type="buttton" class="btn btn-success btn-fill btn-wd" :disabled="(records_bottom===0)" @click="checkForm('bottom')"  v-if="info_page.form_bottom">
                                        {{$t('form.update')}}
                                    </button>
                                </div>


                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>

</template>
<script>
    import swal from 'sweetalert2'
    import {Breadcrumb, BreadcrumbItem} from 'src/components/index'
    import PropertyService from '../../../js/services/PropertyService';
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';
    const propertyService = new PropertyService();
    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress';
    import { mapGetters } from "vuex";
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'
    import moment from 'moment-timezone';
    const logFunctions = new LogFunctions();
    const logService = new LogService();


    export default {
        components: {
            Breadcrumb,
            BreadcrumbItem,
            vueTopprogress
        },
        computed: {
          ...mapGetters(["currentUser","selected_property","writting_permission"]),
        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                screenLoading: false,
                screenText: '',
                records: 0,
                records_bottom: 0,
                breadcrumbs:[
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },
                    {
                        name: this.$t('property.index.property'),
                        path: '/admin/properties',
                        type: '',
                    },

                    {
                        name: this.$t('property.data.header'),
                        path: '',
                        type: 'active',
                    }
                ],
                info_page:{
                    original_info:{},
                    section_name: 'property',
                    property_id: {},
                    tab_lang: 'es',
                    langs:[{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    },{
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },],
                    form: false,
                    form_bottom: false
                },
                property: {
                    _id: '',
                    name: '',
                    rfc: '',
                    business_name: '',
                    b_street: '',
                    b_ext_no: '',
                    b_int_no: '',
                    b_suburb: '',
                    b_zp: '',
                    b_country: '',
                    b_state: '',
                    b_city: '',
                    b_cfdi_usage: '',
                    contact_name: '',
                    contact_phone: '',
                    contact_email: '',
                },
                section: 'PROPERTY'

            }
        },
        created(){
            this.chargerItems(this.selected_property._id);
            this.$store.dispatch('CHANGE_SECTION',this.section);
        },
        methods: {

            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='DataProperty'){
                        this.chargerItems(property_id);
                    }
                })
            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 1;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },
            chargerItems(property_id = null){

                this.info_page.property_id = property_id;
                this.configLogs(property_id, this.info_page.section_name);
                this.initLoading();
                this.info_page.form = false;
                this.records_bottom = 0;
                this.records = 0;

               //console.log(this.info_page.hotel_is);

                propertyService.getPropertyId(property_id).then(response => {
                    let data = response.data.data.property;
                    this.info_page.original_info = JSON.parse(JSON.stringify(data))

                   //console.log('data', data);


                    this.property._id = data._id;
                    this.property.name = data.name;
                    this.property.rfc = data.rfc;
                    this.property.business_name = data.business_name;
                    this.property.b_street = data.b_street;
                    this.property.b_ext_no = data.b_ext_no;
                    this.property.b_int_no = data.b_int_no;
                    this.property.b_suburb = data.b_suburb;
                    this.property.b_zp = data.b_zp;
                    this.property.b_country = data.b_country;
                    this.property.b_state = data.b_state;
                    this.property.b_city = data.b_city;
                    this.property.b_cfdi_usage = data.b_cfdi_usage;
                    this.property.contact_name = data.contact_name;
                    this.property.contact_phone = data.contact_phone;
                    this.property.contact_email = data.contact_email;
                    //this.property.name = data.name;

                    setTimeout(() => {
                        this.closeLoading();
                    }, 500);
                });
            },
            changesHistory(element = 'top'){
                if(element === 'bottom'){
                    this.records_bottom = 1;
                }else{
                    this.records = 1;
                }
                element++;
                this.$store.dispatch('CHANGES_ACTIVES', true);

            },

            checkForm(option){
                let datas = {};
                let errors = 0;
                //console.log('check form')

                switch (option) {
                    case 'top':
                        //this.error.money = false;
                        datas  = {
                            rfc             :   this.property.rfc,
                            business_name   :   this.property.business_name,
                            b_street        :   this.property.b_street,
                            b_ext_no        :   this.property.b_ext_no,
                            b_int_no        :   this.property.b_int_no,
                            b_suburb        :   this.property.b_suburb,
                            b_zp            :   this.property.b_zp,
                            b_country       :   this.property.b_country,
                            b_state         :   this.property.b_state,
                            b_city          :   this.property.b_city,
                        };
                        /*if(this.validNumber(this.configuration.type_money)){
                            errors++;
                            this.error.money = true;
                        }*/




                        break;
                    case 'bottom':
                        datas  = {
                            b_cfdi_usage    :   this.property.b_cfdi_usage,
                            contact_name    :   this.property.contact_name,
                            contact_phone   :   this.property.contact_phone,
                            contact_email   :   this.property.contact_email
                        };

                        break;

                }


                if(errors===0){
                    this.sendForm(datas, option);
                }



            },
            sendForm(data, option){
                this.initLoading('Guardando');
                propertyService.updateDataProperty(this.info_page.property_id, data)
                    .then(async (response) => {


                        let propertyInfo = response.data.data.property;
                        let changes = await logFunctions.cheackProp(this.info_page.original_info, propertyInfo, this.info_page.section_name)
                        if(changes.length){
                            await this.createLog(propertyInfo._id, 1, changes);
                        }
                       //console.log(response);

                    })
                    .catch(error => {
                       //console.log(error);
                    })
                    .finally(() => {
                        switch (option) {
                            case 'top':

                                this.showSwal(this.$t('property.data.msg_top'));
                                this.info_page.form = false;
                                break;
                            case 'bottom':
                                this.showSwal(this.$t('property.data.msg_bottom'));
                                this.info_page.form_bottom = false;

                                break;

                        }
                        this.closeLoading();
                    });
            },
            showSwal (msg) {

                swal({
                    title: this.$t('property.new.modal'),
                    text: msg,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'success'
                }).then(()=> {
                    /* this.$router.push({
                         name: 'Contracts'
                     });*/
                });
            },
            initLoading(text = 'Cargando...') {
                if(text==='Cargando...'){
                    this.$store.dispatch('CHANGES_ACTIVES', false);
                    this.records = 0;
                }
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            }

        },

        mounted() {
            this.changeProperty()
        }
    }
</script>
<style lang="scss">

    .box-info {
        border: 2px solid #eeeeee;
        button{
            vertical-align: middle;
            margin: auto;
            position: absolute;
            right: 30px;
            top: 40px;
            bottom: 0;
            height: 35px;
        }
        .box-top, .box-bottom {
            padding: 25px;
            position: relative;
            .action-form{
                position: absolute;
                right: 15px;
                top: 15px;
                font-size: 25px;
                color: #0e0e0e;
            }
        }
        .box-top{
            border-bottom: 2px solid #eeeeee;
        }
        .group-data-item{
            label:first-child{
                width: 201px;
                height: 30px;
                line-height: 30px;
                vertical-align: top;
            }
            display: inline-block;
            width: 100%;
            .inp-itm{
                margin-left:15px;
                width: 30%;
                display: inline-block;
                height: 30px;
                line-height: 30px;
            }

        }
        label{
            color: #000;
            width: auto;
            display: inline-block;
            margin-bottom: 15px;
            font-weight: 600;
            span{
                font-weight: 400;
            }
        }
        h6{
            margin-bottom: 35px;
            color: #085394;
        }
    }
</style>
